<template>
  <div class="about">
    <h1>{{loginStatus}}</h1>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="require('../assets/logo.svg')"
            class="my-3"
            contain
            height="200"
          />
        </v-col>

        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            パイカメラ テスト
          </h1>

          <p class="subheading font-weight-regular">
            サーバレス(AWS)なカメラ画像配信ページを提供、
            <br>please join our online
            <a
              href="https://www.kakimoto.co.jp/"
              target="_blank"
            >柿本商会HP</a>
          </p>
        </v-col>

        <v-col
          class="mb-5"
          cols="12"
        >
          <h2 class="headline font-weight-bold mb-3">
            AWSコンソール
          </h2>

          <v-row justify="center">
            <a
              v-for="(next, i) in whatsNext"
              :key="i"
              :href="next.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ next.text }}
            </a>
          </v-row>
        </v-col>

        <v-col
          class="mb-5"
          cols="12"
        >
          <h2 class="headline font-weight-bold mb-3">
            参考リンク
          </h2>

          <v-row justify="center">
            <a
              v-for="(link, i) in importantLinks"
              :key="i"
              :href="link.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ link.text }}
            </a>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card-actions>
            <v-btn 
              class="info"
              @click="submit"
            >最新画像へ戻る</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth } from 'aws-amplify';

  export default {
    name: 'About',

    data: () => ({
      loginStatus: '',
      importantLinks: [
        {
          text: 'Vuetify.js',
          href: 'https://vuetifyjs.com',
        },
      ],
      whatsNext: [
        {
          text: 'トップページ',
          href: 'https://aws.amazon.com/jp/console/',
        },
      ],
    }),

    beforeCreate: () => {
      Auth.currentSession()
        .then(() => {
          //console.log ('ログイン済');
        })
        .catch(() => {
          //console.log ('ログイン未');
        });
    },

    methods: {
      submit() {
        this.$router.push('/login');
      }
    },

  }
</script>